$header-height : 10vh;

.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.Navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: $header-height;
  padding: 30px;

  img {
    width: auto;
  }

  .logo {
    margin-left: 30px;
    display: flex;
    flex: 3;
    justify-content: flex-start;
  }
  
  .nav-tabs {
    display: flex;
    flex: 2;
    flex-direction: row;
    justify-content: space-evenly;

    .tab {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
    }
  }

  .link {
    text-decoration: none;
    color: inherit;
    position: relative;

    h2 {
      margin: 0;
      padding-bottom: 8px;
      position: relative;
    }

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 5px;
      background-color: black;
      bottom: 0;
      left: 0;
      transition: width 0.3s ease-in-out;
    }
  
    &:hover::after{
      width: 100%;
    }

}
}

.home {
  padding: 5vw;

  .tabs-and-contact {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items:flex-start;
    width: 100%;
    row-gap: 30px;
    flex-wrap: wrap;

    .headings {
      padding-left: 0;
    }

    > div {
      flex: 1 1;
      padding: 20px;
    }
  }
}

.page {
  font-size: x-large;
  font-weight: 300;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5vw;
  padding-right: 5vw;

  .headings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    row-gap: 10px;
  }

  .topHeading {
    font-size: xxx-large;
    font-weight: 500;
  }

  .subHeading {
    font-size: xx-large;
    font-weight: 400;
  }

  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 15px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    column-gap: 40px;

    

    .tab-button {
      display: flex;
      cursor: pointer;
      position: relative;
      padding-bottom: 8px;

      &::after {
        content: '';
        position: absolute;
        width: 0;
        height: 5px;
        background-color: #007bff;
        bottom: 0;
        left: 0;
        transition: width 0.3s ease-in-out;
      }
    }

    

    .active {
      font-weight: 500;
      color: #007bff;

      &::after{
        width: 100%;
      }
      
    }
  }

  .tab-content {
    display: flex;
    flex-direction: column;

    .section {
      display: flex;
      flex-direction: column;
    }

    .headings {
      padding-bottom: 0px;
      padding-left: 20px  !important;

      h4, h6 {
        margin: 0px;
        font-weight: 450;
      }
    }

    .content {
      padding: 0px 10px;
    }

    strong {
      font-weight: 450;
    }
  }

}



.project-grid {
  grid-gap: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  
  .project-tile {
    display: flex;
    flex-direction: column;
    padding: 1rem 1.3rem;
    position: relative;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border-radius: 20px;

    .project-info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      margin-bottom: 20px;

      h3 {
        height: 66px;
        margin-bottom: 0px;
      }

      p {
        margin-top: 10px;
      }

      button {
        padding: 0.75rem 1.5rem;
        border: none;
        border-radius: 8px;
        background-color: #007bff;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        letter-spacing: 0.05em;
        
        &:hover {
          background-color: #0056b3; /* Darken the button on hover */
          box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Larger shadow on hover */
          transform: translateY(-2px); /* Slight lift on hover */
        }

        &:active {
          background-color: #003d80; /* Even darker blue on active */
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2); /* Smaller shadow on click */
          transform: translateY(0); /* No lift when clicked */
        }
      
    }

  }
    
    .project-image {
      display: flex;
      height: 350px;
      justify-content: center;
      flex-direction: column;
     

      img {
        max-width: 100%;
        max-height: 350px;
      }
    }

  }
}

.individual {

  .project-descriptions {
    display: flex;
    flex-direction: row;
  }

  .headings {

    a {
      text-decoration: none;
    }

    button {
      border: none;
      background-color: white;
      color: #5f6368;
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      cursor: pointer;
      letter-spacing: 0.05em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  .project-image {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    
    img {
      max-width: 70%;
      height: auto;
    }
  }

  .project-info {
    flex: 1;
    display: flex;
    flex-direction: column;

    p {
      font-size: xx-large;
    }
  }
}

.external-links {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;

  a {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    text-decoration: none;
    color: #333;
    font-weight: bold;
    font-size: x-large;
    padding: 10px 20px;
    border: 2px solid #333;
    border-radius: 12px;
    transition: all 0.3s ease;

    &:hover {
      color: #007bff;
      border-color: #007bff;
      transform: translateY(-2px);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    }

    img {
      width: 30px;
      height: 30px;
    }

  }
}